import { IdentStatus, VerificationStatus } from '@/constants/common';

export enum RouteName {
  IdentSesRegistration = '/ident/ses-registration',
  IdentRegistration = '/ident/registration',
  IdentDocumentSelector = '/ident/document-selector',
  IdentPersonalDetails = '/ident/personal-details',
  IdentLoadingScreen = '/ident/loading',
  IdentRecordType = '/ident/record-type',
  IdentDocumentUpload = '/ident/document-upload',
  IdentLocalLogin = '/ident/local-login',
  LegalEntitySearch = '/verify/search',
  LegalEntityCompanyData = '/verify/company-data',
  LegalEntityRepresentative = '/verify/representative',
  LegalEntityPowerOfAttornery = '/verify/power-of-attorney',
  LegalEntityPowerOfAttorneryDocument = '/verify/power-of-attorney-document',
  LegalEntitySuccess = '/verify/success',
  DocumentsUpload = '/verify/documents-upload',
}

export enum WaasRouteName {
  WaasLogin = '/waas/login',
  WaasDownloadApp = '/waas/download',
  WaasDeviceRedirect = '/waas/device-redirect',
  WaasOpenQrScanner = '/waas/open-qr-scanner',
  WaasDocumentSelector = '/waas/document-selector',
  WaasPersonalDetails = '/waas/personal-details',
  WaasSuccess = '/waas/success',
}

export const LocalSignUrl = `${process.env.VERIMI_FRONTEND_URL}/forward/local-sign.html`;

export const IdentRouteMap = {
  [IdentStatus.Initialized]: RouteName.IdentRegistration,
  [IdentStatus.Success]: RouteName.IdentLoadingScreen,
  [IdentStatus.Canceled]: RouteName.IdentLoadingScreen,
  [IdentStatus.Failed]: RouteName.IdentLoadingScreen,
  [IdentStatus.Timeout]: RouteName.IdentLoadingScreen,
  [IdentStatus.ReviewPending]: RouteName.IdentDocumentSelector,
  [IdentStatus.SharePending]: RouteName.IdentLocalLogin,
};

export const VerificationRouteMap = {
  [VerificationStatus.ReviewPending]: RouteName.LegalEntitySearch,
  [VerificationStatus.Success]: RouteName.IdentLoadingScreen,
};

export const VERIFY_ROUTES: RouteName[] = [
  RouteName.LegalEntitySearch,
  RouteName.LegalEntityCompanyData,
  RouteName.LegalEntityRepresentative,
  RouteName.LegalEntityPowerOfAttornery,
  RouteName.LegalEntityPowerOfAttorneryDocument,
  RouteName.LegalEntitySuccess,
  RouteName.DocumentsUpload,
];

export const IDENT_ROUTES: RouteName[] = [
  RouteName.IdentSesRegistration,
  RouteName.IdentRegistration,
  RouteName.IdentDocumentSelector,
  RouteName.IdentPersonalDetails,
  RouteName.IdentLoadingScreen,
  RouteName.IdentRecordType,
  RouteName.IdentDocumentUpload,
  RouteName.IdentLocalLogin,
];
